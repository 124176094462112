/*
| Developed by Dirupt
| Filename : index.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import React from 'react'
import Box from '@mui/material/Box'
import { useScroll } from 'framer-motion'
import Footer from './Footer'
import Header from './Header'
import { usePathname } from '@/routes/hooks'
import ScrollProgress from '@/components/scroll-progress'

/*
|--------------------------------------------------------------------------
| Contracts
|--------------------------------------------------------------------------
*/
type Props = {
	children: React.ReactNode
}

/*
|--------------------------------------------------------------------------
| Main layout
|--------------------------------------------------------------------------
*/
export default function MainLayout({ children }: Props) {
	const pathname = usePathname()
	const homePage = React.useMemo(() => pathname === '/', [pathname])
	const { scrollYProgress } = useScroll()

	return (
		<React.Fragment>
			<ScrollProgress scrollYProgress={scrollYProgress} />
			<Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }}>
				<Header />

				<Box
					component="main"
					sx={{
						flexGrow: 1,
						...(!homePage && {
							pt: { xs: 8, md: 10 },
						}),
					}}
				>
					{children}
				</Box>

				<Footer />
			</Box>
		</React.Fragment>
	)
}
