/*
| Developed by Dirupt
| Filename : scrollbar.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import React from 'react'
import Box from '@mui/material/Box'
import { ScrollbarProps } from './types'
import { StyledScrollbar, StyledRootScrollbar } from './styles'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
const Scrollbar = React.forwardRef<HTMLDivElement, ScrollbarProps>(({ children, sx, ...other }, ref) => {
	const userAgent = typeof navigator === 'undefined' ? 'SSR' : navigator.userAgent
	const mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)

	if (mobile) {
		return (
			<Box ref={ref} sx={{ overflow: 'auto', ...sx }} {...other}>
				{children}
			</Box>
		)
	}

	return (
		<StyledRootScrollbar>
			<StyledScrollbar
				scrollableNodeProps={{
					ref,
				}}
				clickOnTrack={false}
				sx={sx}
				{...other}
			>
				{children}
			</StyledScrollbar>
		</StyledRootScrollbar>
	)
})

Scrollbar.displayName = 'Scrollbar'

export default React.memo(Scrollbar)
