/*
| Developed by Dirupt
| Filename : nav-list.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import { useRef, useState, useEffect, useCallback } from 'react'
import Stack from '@mui/material/Stack'
import Popover from '@mui/material/Popover'
import { NavListProps, NavSubListProps } from '../types'
import NavItem from './nav-item'
import { useActiveLink } from '@/routes/hooks/useActiveLink'
import { usePathname } from '@/routes/hooks'

/*
|--------------------------------------------------------------------------
| Sub component
|--------------------------------------------------------------------------
*/
function NavSubList({ data, depth, slotProps }: NavSubListProps) {
	return (
		<Stack spacing={0.5}>
			{data.map((list) => (
				// eslint-disable-next-line @typescript-eslint/no-use-before-define,no-use-before-define
				<NavList key={list.title} data={list} depth={depth + 1} slotProps={slotProps} />
			))}
		</Stack>
	)
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export default function NavList({ data, depth, slotProps }: NavListProps) {
	const navRef = useRef<HTMLDivElement | null>(null)
	const pathname = usePathname()
	const active = useActiveLink(data.path, !!data.children)
	const [openMenu, setOpenMenu] = useState(false)

	// If openMenu is true, close the menu
	// ----------------------------------------------------------------------------
	useEffect(() => {
		if (openMenu) {
			handleCloseMenu()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname])

	// Methods
	// ----------------------------------------------------------------------------
	const handleOpenMenu = useCallback(() => {
		if (data.children) {
			setOpenMenu(true)
		}
	}, [data.children])

	const handleCloseMenu = useCallback(() => {
		setOpenMenu(false)
	}, [])

	// Render
	// ----------------------------------------------------------------------------
	return (
		<>
			<NavItem
				ref={navRef}
				open={openMenu}
				onMouseEnter={handleOpenMenu}
				onMouseLeave={handleCloseMenu}
				//
				title={data.title}
				path={data.path}
				icon={data.icon}
				info={data.info}
				roles={data.roles}
				caption={data.caption}
				disabled={data.disabled}
				//
				depth={depth}
				hasChild={!!data.children}
				externalLink={data.path.includes('http')}
				currentRole={slotProps?.currentRole}
				//
				active={active}
				className={active ? 'active' : ''}
				sx={depth === 1 ? slotProps?.rootItem : slotProps?.subItem}
			/>

			{data.children ? (
				<Popover
					disableScrollLock
					open={openMenu}
					anchorEl={navRef.current}
					anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
					transformOrigin={{ vertical: 'center', horizontal: 'left' }}
					slotProps={{
						paper: {
							onMouseEnter: handleOpenMenu,
							onMouseLeave: handleCloseMenu,
							sx: {
								mt: 0.5,
								minWidth: 160,
								...(openMenu && {
									pointerEvents: 'auto',
								}),
							},
						},
					}}
					sx={{
						pointerEvents: 'none',
					}}
				>
					<NavSubList data={data.children} depth={depth} slotProps={slotProps} />
				</Popover>
			) : null}
		</>
	)
}
