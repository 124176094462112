/*
| Developed by Dirupt
| Filename : nav-item.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import React from 'react'
import { styled } from '@mui/material/styles'
import Link from '@mui/material/Link'
import ListItemButton from '@mui/material/ListItemButton'
import { NavItemProps, NavItemStateProps } from '../types'
import Iconify from '@/components/iconify'
import { RouterLink } from '@/routes/components'

/*
|--------------------------------------------------------------------------
| Styles
|--------------------------------------------------------------------------
*/
const StyledNavItem = styled(ListItemButton, {
	shouldForwardProp: (prop) => prop !== 'active' && prop !== 'subItem',
})<NavItemStateProps>(({ open, active, subItem, theme }) => {
	const opened = open && !active

	const outlineStyles = {
		width: 6,
		height: 6,
		left: -12,
		opacity: 0.64,
		content: '""',
		borderRadius: '50%',
		position: 'absolute',
		backgroundColor: 'currentColor',
		...(active && {
			color: theme.palette.primary.main,
		}),
	}

	return {
		// Root item
		...(!subItem && {
			...theme.typography.body2,
			padding: 0,
			height: '100%',
			fontWeight: theme.typography.fontWeightMedium,
			transition: theme.transitions.create(['all'], {
				duration: theme.transitions.duration.shorter,
			}),
			'&:hover': {
				opacity: 0.64,
				backgroundColor: 'transparent',
				'&:before': {
					...outlineStyles,
				},
			},
			...(active && {
				color: theme.palette.primary.main,
				fontWeight: theme.typography.fontWeightSemiBold,
				'&:before': {
					...outlineStyles,
				},
			}),
			...(opened && {
				opacity: 0.64,
				'&:before': {
					...outlineStyles,
				},
			}),
		}),

		// Sub item
		...(subItem && {
			...theme.typography.body2,
			padding: 0,
			fontSize: 13,
			color: theme.palette.text.secondary,
			fontWeight: theme.typography.fontWeightMedium,
			transition: theme.transitions.create(['all'], {
				duration: theme.transitions.duration.shorter,
			}),
			'&:hover': {
				backgroundColor: 'transparent',
				color: theme.palette.text.primary,
				'&:before': {
					...outlineStyles,
				},
			},
			...(active && {
				color: theme.palette.text.primary,
				fontWeight: theme.typography.fontWeightSemiBold,
				'&:before': {
					...outlineStyles,
				},
			}),
		}),
	}
})

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export const NavItem = React.forwardRef<HTMLDivElement, NavItemProps>(
	({ title, path, open, active, hasChild, externalLink, subItem, ...other }, ref) => {
		const renderContent = (
			<StyledNavItem
				disableRipple
				disableTouchRipple
				ref={ref}
				open={open}
				active={active}
				subItem={subItem}
				{...other}
			>
				{title}

				{hasChild ? <Iconify width={16} icon="eva:arrow-ios-downward-fill" sx={{ ml: 1 }} /> : null}
			</StyledNavItem>
		)

		if (hasChild) {
			return renderContent
		}

		if (externalLink) {
			return (
				<Link href={path} target="_blank" rel="noopener" color="inherit" underline="none">
					{renderContent}
				</Link>
			)
		}

		return (
			<Link component={RouterLink} href={path} color="inherit" underline="none">
				{renderContent}
			</Link>
		)
	},
)

NavItem.displayName = 'NavItem'
