/*
| Developed by Dirupt
| Filename : Header.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Button from '@mui/material/Button'
import Toolbar from '@mui/material/Toolbar'
import { useTheme } from '@mui/material/styles'
import Container from '@mui/material/Container'
import Stack from '@mui/material/Stack'
import HeaderShadow from '../common/HeaderShadow'
import NavDesktop from './nav/desktop'
import NavMobile from './nav/mobile'
import { navConfig } from '@/config/nav.config'
import { HEADER } from '@/config/layout.config'
import { useResponsive } from '@/hooks/useResponsive'
import { useOffSetTop } from '@/hooks/useOffsetTop'
import { urlsConfig } from '@/config/urls.config'
import Logo from '@/components/logo'

/*
|--------------------------------------------------------------------------
| Header
|--------------------------------------------------------------------------
*/
export default function Header() {
	const theme = useTheme()
	const mdUp = useResponsive('up', 'md')
	const offsetTop = useOffSetTop(HEADER.H_DESKTOP)

	return (
		<AppBar>
			<Toolbar
				disableGutters
				sx={{
					height: {
						xs: HEADER.H_MOBILE,
						md: HEADER.H_DESKTOP,
					},
					transition: theme.transitions.create(['height'], {
						easing: theme.transitions.easing.easeInOut,
						duration: theme.transitions.duration.shorter,
					}),
					backgroundColor: theme.palette.background.default,
					...(offsetTop && {
						// ...bgBlur({
						// 	color: theme.palette.background.default,
						// }),
						height: {
							md: HEADER.H_DESKTOP_OFFSET,
						},
					}),
				}}
			>
				<Container sx={{ height: 1, display: 'flex', alignItems: 'center' }}>
					<Logo withText={mdUp ? 'default' : undefined} />

					<Box sx={{ flexGrow: 1 }} />

					{mdUp ? <NavDesktop data={navConfig} /> : null}

					<Stack alignItems="center" direction={{ xs: 'row', md: 'row-reverse' }}>
						<Button
							variant="contained"
							color={'inherit'}
							href={urlsConfig.askQuote}
							aria-label={'Demande de devis'}
						>
							Demande de devis
						</Button>

						{/*{mdUp ? (*/}
						{/*	<Button*/}
						{/*		variant="outlined"*/}
						{/*		color={'inherit'}*/}
						{/*		href={urlsConfig.customerPortal}*/}
						{/*		sx={{ mr: 1 }}*/}
						{/*	>*/}
						{/*		Espace client*/}
						{/*	</Button>*/}
						{/*) : null}*/}

						{!mdUp ? <NavMobile data={navConfig} /> : null}
					</Stack>
				</Container>
			</Toolbar>

			{offsetTop ? <HeaderShadow /> : null}
		</AppBar>
	)
}
