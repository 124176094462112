/*
| Developed by Dirupt
| Filename : index.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import Stack from '@mui/material/Stack'
import { NavProps } from '../types'
import NavList from './nav-list'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export default function NavDesktop({ data }: NavProps) {
	return (
		<Stack component="nav" direction="row" spacing={4} sx={{ mr: 2.5, height: 1 }}>
			{data.map((list) => (
				<NavList key={list.title} data={list} />
			))}
		</Stack>
	)
}
