/*
| Developed by Dirupt
| Filename : index.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import React from 'react'
import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import Box from '@mui/material/Box'
import { NavProps } from '../types'
import NavList from './nav-list'
import Logo from '@/components/logo'
import { usePathname } from '@/routes/hooks'
import Scrollbar from '@/components/scrollbar'
import Iconify from '@/components/iconify'

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export default function NavMobile({ data }: NavProps) {
	const pathname = usePathname()
	const [openMenu, setOpenMenu] = React.useState(false)

	// Life cycle
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		if (openMenu) {
			handleCloseMenu()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname])

	// Methods
	// ----------------------------------------------------------------------------
	const handleOpenMenu = React.useCallback(() => {
		setOpenMenu(true)
	}, [])

	const handleCloseMenu = React.useCallback(() => {
		setOpenMenu(false)
	}, [])

	// Render
	// ----------------------------------------------------------------------------
	return (
		<React.Fragment>
			<IconButton onClick={handleOpenMenu} sx={{ ml: 1 }} aria-label={'Ouvrir le menu mobile'}>
				<Iconify icon="eva:menu-fill" width={24} />
			</IconButton>

			<Drawer
				open={openMenu}
				onClose={handleCloseMenu}
				PaperProps={{
					sx: {
						backgroundColor: 'background.default',
						pb: 5,
						width: 260,
					},
				}}
			>
				<Scrollbar>
					<Box sx={{ mx: 2.5, my: 3 }}>
						<Logo withText={'default'} mobileOnly />
					</Box>

					{data.map((list) => (
						<NavList key={list.title} data={list} />
					))}
				</Scrollbar>
			</Drawer>
		</React.Fragment>
	)
}
