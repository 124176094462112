/*
| Developed by Dirupt
| Filename : Footer.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Divider from '@mui/material/Divider'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Unstable_Grid2'
import Typography from '@mui/material/Typography'
import { RouterLink } from '@/routes/components'
import { urlsConfig } from '@/config/urls.config'
import Logo from '@/components/logo'

/*
|--------------------------------------------------------------------------
| Constants
|--------------------------------------------------------------------------
*/
export const SOCIALS = [
	{
		value: 'facebook',
		name: 'FaceBook',
		icon: 'eva:facebook-fill',
		color: '#1877F2',
		path: 'https://www.facebook.com/',
	},
	{
		value: 'instagram',
		name: 'Instagram',
		icon: 'ant-design:instagram-filled',
		color: '#E02D69',
		path: 'https://www.instagram.com/',
	},
	{
		value: 'linkedin',
		name: 'Linkedin',
		icon: 'eva:linkedin-fill',
		color: '#007EBB',
		path: 'https://www.linkedin.com/',
	},
	{
		value: 'twitter',
		name: 'Twitter',
		icon: 'eva:twitter-fill',
		color: '#00AAEC',
		path: 'https://www.twitter.com/',
	},
]

const LINKS = [
	{
		headline: 'SHPV',
		children: [
			{ name: 'Demander un devis', href: urlsConfig.askQuote, target: undefined },
			{ name: 'Nous contacter', href: urlsConfig.contactUs, target: undefined },
		],
	},
	{
		headline: 'Services',
		children: [
			{ name: 'Services', href: urlsConfig.services.index, target: undefined },
			{ name: 'Infogérance', href: urlsConfig.services.infogerance, target: undefined },
			{ name: 'Infrastructure', href: urlsConfig.services.infrastructure, target: undefined },
			{ name: 'Datacenter', href: urlsConfig.services.datacenter, target: undefined },
		],
	},
	{
		headline: 'Légales',
		children: [
			{ name: "Conditions Générales d'Hébergement", href: urlsConfig.legals.cgHosting, target: '_blank' },
			{ name: 'CGV Cloud', href: urlsConfig.legals.cgHosting, target: '_blank' },
			{ name: 'CGV On Premise', href: urlsConfig.legals.cgHosting, target: '_blank' },
			{ name: 'Services annexes', href: urlsConfig.legals.cgHosting, target: '_blank' },
		],
	},
]

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export default function Footer() {
	return (
		<Box
			component="footer"
			sx={{
				position: 'relative',
				bgcolor: 'background.default',
			}}
		>
			<Divider />

			<Container
				sx={{
					pt: 10,
					pb: 5,
					textAlign: { xs: 'center', md: 'unset' },
				}}
			>
				<Grid
					container
					justifyContent={{
						xs: 'center',
						md: 'space-between',
					}}
				>
					<Grid xs={8} md={3}>
						<Logo sx={{ mb: 3 }} />
						<Typography
							variant="body2"
							sx={{
								maxWidth: 270,
								mx: { xs: 'auto', md: 'unset' },
							}}
						>
							SHPV, votre partenaire de confiance en infrastructure et infogérance informatique en France.
						</Typography>

						{/*<Stack*/}
						{/*	direction="row"*/}
						{/*	justifyContent={{ xs: 'center', md: 'flex-start' }}*/}
						{/*	sx={{*/}
						{/*		mt: 3,*/}
						{/*		mb: { xs: 5, md: 0 },*/}
						{/*	}}*/}
						{/*>*/}
						{/*	{SOCIALS.map((social) => (*/}
						{/*		<IconButton*/}
						{/*			key={social.name}*/}
						{/*			sx={{*/}
						{/*				'&:hover': {*/}
						{/*					bgcolor: alpha(social.color, 0.08),*/}
						{/*				},*/}
						{/*			}}*/}
						{/*		>*/}
						{/*			<Iconify color={social.color} icon={social.icon} />*/}
						{/*		</IconButton>*/}
						{/*	))}*/}
						{/*</Stack>*/}
					</Grid>

					<Grid xs={12} md={8}>
						<Stack spacing={1} direction={{ xs: 'column', md: 'row' }}>
							{LINKS.map((list) => (
								<Stack
									key={list.headline}
									spacing={1}
									alignItems={{ xs: 'center', md: 'flex-start' }}
									sx={{ width: 1 }}
								>
									<Typography component="div" variant="overline">
										{list.headline}
									</Typography>

									{list.children.map((link) => (
										<Link
											key={link.name}
											component={RouterLink}
											href={link.href}
											color="inherit"
											variant="body2"
											aria-label={link.name}
											target={link.target}
										>
											{link.name}
										</Link>
									))}
								</Stack>
							))}
						</Stack>
					</Grid>
				</Grid>

				<Box display={'flex'} flexDirection={{ xs: 'column', md: 'row' }} marginTop={5}>
					<Typography variant="caption" fontWeight={'300'}>
						SHPV FRANCE - SAS au capital de 16 000 € - 52 Rue Romain Rolland, 71230 Saint-Vallier - SIRET
						n°80886287400035 - R.C.S. Chalon-sur-saone. Par téléphone{' '}
						<Link href={'tel:+33972310818'} aria-label={'Appelez nous'}>
							09 72 310 818
						</Link>{' '}
						- Email:{' '}
						<Link href={'mailto:support@shpv.fr'} aria-label={'Écrivez nous'}>
							support@shpv.fr
						</Link>
					</Typography>
				</Box>

				<Box
					display={'flex'}
					flexDirection={{ xs: 'column', md: 'row' }}
					justifyContent={'space-between'}
					marginTop={2}
				>
					<Typography variant="body2" fontWeight={'bold'}>
						SHPV © 2024 - Tous droits réservés
					</Typography>

					<Box>
						<Link
							href={urlsConfig.legalNotices}
							color="inherit"
							variant="body2"
							sx={{
								ml: { xs: 0, md: 'auto' },
								mt: { xs: 2, md: 0 },
							}}
							aria-label={'Mentions légales'}
						>
							Mentions légales
						</Link>
						<Link
							href={urlsConfig.privacyPolicy}
							color="inherit"
							variant="body2"
							sx={{
								ml: 2,
								mt: { xs: 2, md: 0 },
							}}
							aria-label={'Politiques de confidentialité'}
						>
							Politiques de confidentialité
						</Link>
					</Box>
				</Box>
			</Container>
		</Box>
	)
}
