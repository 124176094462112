/*
| Developed by Dirupt
| Filename : nav-list.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import React from 'react'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Portal from '@mui/material/Portal'
import { useTheme } from '@mui/material/styles'
import ListSubheader from '@mui/material/ListSubheader'
import { NavListProps, NavSubListProps } from '../types'
import { NavItem } from './nav-item'
import { HEADER } from '@/config/layout.config'
import { useActiveLink } from '@/routes/hooks/useActiveLink'
import { usePathname } from '@/routes/hooks'
import { paper } from '@/theme/css'

/*
|--------------------------------------------------------------------------
| Sub component
|--------------------------------------------------------------------------
*/
function NavSubList({ data, subheader, sx, ...other }: NavSubListProps) {
	const pathname = usePathname()

	return (
		<Stack
			spacing={2}
			flexGrow={1}
			alignItems="flex-start"
			sx={{
				pb: 2,
				...sx,
			}}
			{...other}
		>
			<ListSubheader
				disableSticky
				sx={{
					p: 0,
					typography: 'overline',
					fontSize: 11,
					color: 'text.primary',
				}}
			>
				{subheader}
			</ListSubheader>

			{data.map((item) => (
				<NavItem
					key={item.title}
					title={item.title}
					path={item.path}
					active={pathname === item.path || pathname === `${item.path}/`}
					subItem
				/>
			))}
		</Stack>
	)
}

/*
|--------------------------------------------------------------------------
| Component
|--------------------------------------------------------------------------
*/
export default function NavList({ data }: NavListProps) {
	const theme = useTheme()
	const pathname = usePathname()
	const active = useActiveLink(data.path, !!data.children)
	const [openMenu, setOpenMenu] = React.useState(false)

	// If the current path is the same as the parent path, we open the menu
	// ----------------------------------------------------------------------------
	React.useEffect(() => {
		if (openMenu) {
			handleCloseMenu()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathname])

	// Methods
	// ----------------------------------------------------------------------------
	const handleOpenMenu = React.useCallback(() => {
		if (data.children) {
			setOpenMenu(true)
		}
	}, [data.children])

	const handleCloseMenu = React.useCallback(() => {
		setOpenMenu(false)
	}, [])

	// Render
	// ----------------------------------------------------------------------------
	return (
		<React.Fragment>
			<NavItem
				open={openMenu}
				onMouseEnter={handleOpenMenu}
				onMouseLeave={handleCloseMenu}
				title={data.title}
				path={data.path}
				hasChild={!!data.children}
				externalLink={data.path.includes('http')}
				active={active}
			/>

			{!!data.children && openMenu ? (
				<Portal>
					<Fade in={openMenu}>
						<Paper
							onMouseEnter={handleOpenMenu}
							onMouseLeave={handleCloseMenu}
							sx={{
								...paper({ theme }),
								left: 0,
								right: 0,
								m: 'auto',
								display: 'flex',
								borderRadius: 2,
								position: 'fixed',
								zIndex: theme.zIndex.modal,
								p: theme.spacing(5, 1, 1, 3),
								top: HEADER.H_DESKTOP_OFFSET,
								maxWidth: theme.breakpoints.values.lg,
								boxShadow: theme.customShadows.dropdown,
							}}
						>
							{data.children.map((list) => (
								<NavSubList key={list.subheader} subheader={list.subheader} data={list.items} />
							))}
						</Paper>
					</Fade>
				</Portal>
			) : null}
		</React.Fragment>
	)
}
