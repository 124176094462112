/*
| Developed by Dirupt
| Filename : nav.config.tsx
| Author : Philippe DESPLATS (philippe@di-rupt.com)
*/

import { urlsConfig } from '@/config/urls.config'
import Iconify from '@/components/iconify'

/*
|--------------------------------------------------------------------------
| Nav config
|--------------------------------------------------------------------------
*/
export const navConfig = [
	{
		title: 'Accueil',
		icon: <Iconify icon="majesticons:home-line" />,
		path: '/',
	},
	{
		title: 'Infrastructure',
		icon: <Iconify icon="majesticons:server-line" />,
		path: urlsConfig.services.infrastructure,
	},
	{
		title: 'Infogérance',
		icon: <Iconify icon="majesticons:monitor-line" />,
		path: urlsConfig.services.infogerance,
	},
	{
		title: 'Datacenter',
		icon: <Iconify icon="majesticons:lock-line" />,
		path: urlsConfig.services.datacenter,
	},
]
